<template>

  <b-modal
      id="modal-add-company"
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      centered
      size="lg"
      v-model="modal.show"
      body-class="modal-body-content"
      :style="{ padding: '0px !important' }"
  >
    <b-overlay :show="loading" rounded="lg" opacity="0.6" class="w-100 mt-1">
    <form-wizard
        color="#2772C0"
        :title="null"
        :subtitle="null"
        :next="false"
        :prev="false"
        ref="wizard"
    >
      <tab-content
          title="Selecionar Empresa"
          icon="feather icon-box"
          :beforeChange="() => true"
      >
        <validation-observer ref="companyForm">
          <b-row>
            <b-col lg="12" md="12" sm="12">
              <b-form-group
                  label="CNPJ | CPF | Nome Fantasia | Razão Social"
                  label-for="name_group"
                  label-class="font_size_label"
                  class="search-container"
              >
                <b-form-input
                    id="company_identifier"
                    class="form-control"
                    placeholder="Digite o termo a ser pesquisado"
                    v-model="searchTerm"
                />
                <div class="search-input-icon">
                  <feather-icon
                      icon="SearchIcon"
                      size="22"
                  />
                </div>
              </b-form-group>
            </b-col>
              <b-col v-if="modal.empresas.length" lg="12" md="12" sm="12">
                <CompanyList
                    :companies="modal.empresas"
                    @companySelected="getCompanySelected"
                />
              </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" class="d-flex align-items-center mt-2">
                <div>Filtrar por:</div>
                <b-form-radio-group
                    id="radio"
                    v-model="searchOptionSelected"
                    name="typeSearch"
                    :options="optionsRadio"
                    class="ml-2 "
                />
            </b-col>
          </b-row>

        </validation-observer>
      </tab-content>

      <tab-content
          title="Selecionar Endereço"
          icon="feather icon-check-square"
          :beforeChange="() => true"
      >
        <div v-if="company_added">
            <AddressCompany
                :addressCompany="company_added[0].empresa_endereco"
                :adesoes="company_added[0].adesoes"
                :prev="prev"
                @addressFiltered="handleAddress"
            />
        </div>
      </tab-content>

        <template slot="footer" slot-scope="props">
          <ButtonsAddCompany
              :stepsData="props"
              :company_added="company_added"
              :address="address"
              :title_button="titleButton"
              :blockButtonAddCompany="blockButtonAddCompany"
              @next="updateNext()"
              @cancelAddCompany="openModalConfirm"
              @prev="prevButton"
              @addCompany="saveCompany"
          />
        </template>
    </form-wizard>
    </b-overlay>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import {BAlert, BCol, BFormGroup, BFormInput, BModal, BRow, BTab, BTabs, BImg,BForm,BButton, BOverlay,BFormRadioGroup} from "bootstrap-vue";
import {
  AdesaoGetApiEmpresasParaAdesao,
  AdesaoAdicionarEmpresaSesi,
  AdesaoGetEmpresas,
} from "@/views/custom-pages/adesao/Purchase/adesaoHelpers.js";
import ButtonsAddCompany from "@/views/custom-pages/GrupoEmpresa/Components/ButtonsAddCompany.vue";
import IncompleteAddress from "@/views/custom-pages/GrupoEmpresa/Components/IncompleteAddress.vue";
import CompanyListToAddGroup from "@/views/custom-pages/GrupoEmpresa/Components/CompanyListToAddGroup.vue";
  import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import CompanyList from "@/views/custom-pages/adesao/components/AdicionarEmpresaAdesaoModal/CompanyList.vue";
import AddressCompany from "@/views/custom-pages/adesao/components/AdicionarEmpresaAdesaoModal/AddressCompany.vue";
import {removesLettersAndSpecialCharacters} from "@/helpers/string";

export default {
  components: {
    AddressCompany,
    CompanyList,
    CompanyListToAddGroup,
    BAlert,
    IncompleteAddress,
    BFormGroup,
    ButtonsAddCompany,
    BRow,
    BCol,
    BFormInput,
    BModal,
    vSelect,
    FormWizard,
    TabContent,
    BTab, BTabs, BImg, BForm,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BOverlay,
    BFormRadioGroup
  },
  props: {
    showModal: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      empresaFilter: 'doc_filter',
      modal: {
        show: this.showModal,
        empresaSelecionada: null,
        empresas: [],
      },
      loading: true,
      prev: false,
      next: null,
      company_added: null,
      titleButton: 'Salvar empresa',
      blockButtonAddCompany: true,
      searchTerm: "",
      searchOptionSelected: "documento",
      optionsRadio: [
        {text: "CNPJ/CPF", value: "documento"},
        {text: "Razão Social", value: "razao_social"},
        {text: "Nome Fantasia", value: "nome_fantasia"},
      ],
      empresasBackup: [],
      address: null,
    };
  },

  beforeMount() {
    this.handleAdicionarEmpresa();
  },

  methods: {
    handleAdicionarEmpresa() {
      AdesaoGetApiEmpresasParaAdesao().then((empresas) => {
        let idEmpresaJaExistenteNaSessao = AdesaoGetEmpresas().map(
          (empresa) => empresa.id_empresa
        );

        this.modal.empresas = empresas.filter((empresa) => {
          return !idEmpresaJaExistenteNaSessao.includes(empresa.id_empresa);
        });

        this.modal.empresas = this.modal.empresas.map((empresa) => {
          return {
            ...empresa,
            documento_formatado: this.formatCnpjCpf(empresa.documento),
            doc_filter: this.formatCnpjCpf(empresa.documento) + ' ' + empresa.documento,
          };
        });

        this.empresasBackup = this.modal.empresas

        this.loading = false;
      });
    },

    saveCompany() {
      AdesaoAdicionarEmpresaSesi(this.company_added[0]);
      this.fecharModal()
    },

    getCompanySelected(company) {
      this.company_added = company
    },

    handleAddress(address) {
      this.company_added[0].empresa_endereco_selecionado = address
      this.address = address
    },

    prevButton(value) {
      this.prev = value
      this.company_added = null
      this.address = null
    },

    updateNext() {
      this.next = true
    },

    fecharModal() {
      this.modal = {
        show: false,
        empresaSelecionada: null,
        empresas: [],
      };
      this.$emit("fecharModal");
    },

    formatCnpjCpf(document) {
      if (document.length === 14) {
          return this.$options.filters.VMask(document, '##.###.###/####-##');
        } else if (document.length === 11) {
          return this.$options.filters.VMask(document, '###.###.###-##');
        } else {
          return document;
        }
    },

    openModalConfirm() {
      this.$swal({
        title: 'Tem certeza de que deseja fechar sem salvar as alterações?',
        icon: 'warning',
        iconColor: "orange",
        html: '<div>As mudanças não salvas serão perdidas.</div>',
        confirmButtonText: 'Continuar editando',
        showCancelButton: true,
        cancelButtonText: 'Sim, cancelar',
        customClass: {
          confirmButton: "btn btn-custom-blue mr-2",
          cancelButton: 'btn btn-outline-warning'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.isDismissed) {
          this.modal.show = false
          this.$emit('fecharModal')
        }
      })

    },

    searchByDocument(search) {
      this.modal.empresas = this.modal.empresas.filter((empresa) => empresa.documento.startsWith(search))
    },

    searchByRazaoSocial(search) {
      const normalizedSearch = search.toLowerCase().trim();
      this.modal.empresas = this.modal.empresas.filter((empresa) =>
          empresa.razao_social.toLowerCase().trim().startsWith(normalizedSearch)
      );
    },

    searchByNomeFantasia(search) {
      const normalizedSearch = search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim();
      this.modal.empresas = this.modal.empresas.filter((empresa) =>
          empresa.nome_empresa
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .trim()
              .startsWith(normalizedSearch)
      );
    },

    recuperaBackupListaEmpresas() {
      this.modal.empresas = this.empresasBackup
    }
  },

  watch: {
    searchTerm : function(search) {
      this.recuperaBackupListaEmpresas();

      if(search !== '') {
        const searchFunctions = {
          'documento': this.searchByDocument,
          'razao_social': this.searchByRazaoSocial,
          'nome_fantasia': this.searchByNomeFantasia,
        }

        const searchFunction = searchFunctions[this.searchOptionSelected]

        if(searchFunction && this.searchOptionSelected == 'documento') {
          search = removesLettersAndSpecialCharacters(search)
        }

        searchFunction?.(search)
      }
    },

    address() {
      if(this.address != null && this.company_added != null) {
        this.blockButtonAddCompany = false
      }
    }
  }
};
</script>

<style>
#modal-add-company .modal-body {
  padding: 0px !important;
}

.modal-add-company {
  padding: 0 !important;
}

.buttons {
  text-align: center;
  margin-top: 15px;
}

.search-container {
  position: relative;
}

.search-input-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  cursor: pointer;
  color: #b6b2b5;
  font-weight: bold;
}

.container-icon {
  background-color: #2772c0;
  border-radius: 5px;
  margin-right: 5px;
  padding: 5px;
}

.container-icon img {
  filter: invert(50%) sepia(100%) saturate(100%) hue-rotate(0deg) brightness(100%) contrast(100%);
}


.alert-company > span > strong {
  color: #FF9110;
  font-weight: 900;
}

.alert-company > span {
  font-weight: 500;
  font-size: 16px;
}

.info-company {
  background-color: #e5eef7;
}

.info-company > span > strong{
  font-weight: 900;
  color: #2772C0;
}

.info-company > span {
  color: #2772C0;
  font-size: 16px;
  font-weight: 500;
}

custom-width {
  width: 50px;
  flex-shrink: 0;
}

.container-company-info > h4{
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #6E6B7B;
}

.container-company-info div {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6E6B7B;
}

#empresa-select .modal-header {
  background: none !important;
}

#empresa-select .modal-dialog {
  max-width: 550px !important;
}
.radio-empresa .custom-radio.b-custom-control-sm .custom-control-label::before, [dir=ltr] .input-group-sm .custom-radio .custom-control-label::before {
  left: 0;
}
</style>